export const convertDateFields = (obj: any): any => {
  if (Array.isArray(obj)) {
    // If obj is an array, apply the function to each element
    return obj.map(convertDateFields);
  } else if (typeof obj === "object" && obj !== null) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key) && isDateObject(obj[key])) {
        obj[key] = createDateFromTimestamp(obj[key]);
      }
    }
  }
  return obj;
};

const isDateObject = (value: any): boolean => {
  return (
    typeof value === "object" &&
    value !== null &&
    value.hasOwnProperty("seconds") &&
    value.hasOwnProperty("nanoseconds")
  );
};

const createDateFromTimestamp = (timestamp: any): Date => {
  return new Date(
    (timestamp as any)?.seconds * 1000 +
      (timestamp as any)?.nanoseconds / 1000000,
  );
};
