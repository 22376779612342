import { message } from "antd";
import {
  getAccountingTypes,
  createAccounting,
  ICreateAccountingRequest,
  IAccountingType,
  getDailyAccounting,
  IGetDailyAccountingRequest,
  IDailyAccounting,
} from "tasit-defteri-firebase-functions";

export async function createAccountingRequest(
  payload: ICreateAccountingRequest,
): Promise<void> {
  try {
    await createAccounting(payload);
    message.success("Masraf eklendi.");
  } catch (error: any) {
    message.error(error.message);
  }
}

export async function getAccountingTypesRequest(): Promise<IAccountingType[]> {
  try {
    const result = await getAccountingTypes();
    return result;
  } catch (error: any) {
    message.error(error.message);
    return [];
  }
}

export async function getDailyAccountingRequest(
  payload: IGetDailyAccountingRequest,
): Promise<IDailyAccounting | null> {
  try {
    const result = await getDailyAccounting(payload);
    return result;
  } catch (error: any) {
    message.error(error.message);
    return null;
  }
}
