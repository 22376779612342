import { message } from "antd";
import {
  getFCMToken,
  createFCMToken,
  ICreateFCMTokenRequest,
} from "tasit-defteri-firebase-functions";

export async function getFCMTokenRequest(): Promise<string | undefined> {
  try {
    const vapidKey = process.env.REACT_APP_FIREBASE_VAPID_KEY;
    if (vapidKey) {
      const result = await getFCMToken({
        vapidKey,
      });
      return result;
    }
  } catch (error: any) {
    message.error(error.message);
  }
}

export async function createFCMTokenRequest(
  payload: ICreateFCMTokenRequest,
): Promise<string | undefined> {
  try {
    const result = await createFCMToken(payload);
    return result;
  } catch (error: any) {
    message.error(error.message);
  }
}
