import { message } from "antd";
import {
  createOrUpdateVehicle,
  ICreateOrUpdateVehicleRequest,
  deleteVehicle,
  IDeleteVehicleRequest,
  IVehicle,
  getVehicleById,
  IGetVehicleByIdRequest,
} from "tasit-defteri-firebase-functions";

export async function createOrUpdateVehicleRequest(
  payload: ICreateOrUpdateVehicleRequest,
): Promise<IVehicle | undefined> {
  try {
    const result = await createOrUpdateVehicle(payload);
    message.success("Araç kaydedildi!");
    return result;
  } catch (error: any) {
    message.error(error.message);
  }
}

export async function getVehicleByIdRequest(
  payload: IGetVehicleByIdRequest,
): Promise<IVehicle | undefined> {
  try {
    const result = await getVehicleById(payload);
    return result;
  } catch (error: any) {
    message.error(error.message);
  }
}

export async function deleteVehicleRequest(
  payload: IDeleteVehicleRequest,
): Promise<void> {
  try {
    await deleteVehicle(payload);
    message.success("Araç Silindi!");
  } catch (error: any) {
    message.error(error.message);
  }
}
