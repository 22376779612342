import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { AppContextProvider } from "./context/app.context";
import { FirebaseService } from "tasit-defteri-firebase-functions";
import "@fontsource/balsamiq-sans/400.css";
import "@fontsource/balsamiq-sans/700.css";

import App from "./App";
import "./style.css";

FirebaseService({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY || "",
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || "",
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID || "",
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || "",
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGGING_SENDER_ID || "",
  appId: process.env.REACT_APP_FIREBASE_APP_ID || "",
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID || "",
  workWithFirebaseEmulator:
    process.env.REACT_APP_FIREBASE_EMULATOR?.toLowerCase() === "true",
});

if (navigator.serviceWorker) {
  // Register the SW
  navigator.serviceWorker
    .register("/firebase-messaging-sw.js")
    .then(() => console.log("Firebase background notification registration"))
    .catch(() => console.error("Firebase background notification error"));
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <BrowserRouter>
    <AppContextProvider>
      <App />
    </AppContextProvider>
  </BrowserRouter>,
);
