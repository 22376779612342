import dayjs from "dayjs";

const getStartOfYear = (): dayjs.Dayjs => {
  return dayjs().startOf("year");
};

const getEndOfYear = (): dayjs.Dayjs => {
  return dayjs().endOf("year");
};

const setToEndOfDay = (date = dayjs()): dayjs.Dayjs => {
  return date.endOf("day");
};

const setToStartOfDay = (date = dayjs()): dayjs.Dayjs => {
  return date.startOf("day");
};

export { dayjs, getStartOfYear, getEndOfYear, setToEndOfDay, setToStartOfDay };
