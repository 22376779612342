import { message } from "antd";
import {
  createOrUpdateUser,
  getUserByPhone,
  ICreateOrUpdateUserRequest,
  IGetUserByPhoneRequest,
  IUser,
} from "tasit-defteri-firebase-functions";

export async function getUserByPhoneRequest(
  payload: IGetUserByPhoneRequest,
): Promise<IUser | null | undefined> {
  try {
    return await getUserByPhone(payload);
  } catch (error: any) {
    message.error(error.message);
  }
}

export async function createOrUpdateUserRequest(
  payload: ICreateOrUpdateUserRequest,
): Promise<IUser | undefined> {
  try {
    const result = await createOrUpdateUser(payload);
    message.success("Hesap bilgileri güncellendi!");
    return result;
  } catch (error: any) {
    message.error(error.message);
  }
}
