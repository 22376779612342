import React, { Suspense } from "react";
import { ConfigProvider, Layout, Grid } from "antd";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { Url } from "./constants/urls";
import SplashScreen from "./components/SplashScreen";
import Loader from "./components/Loader";
import Header from "./containers/Header";
import Notification from "./components/Notification";
import SideMenu from "./containers/SideMenu";
import Breadcrumbs from "./containers/Breadcrumbs";
import { UserType } from "./containers/UserTypeSwitcher/types";
import { useApp } from "./context/app.context";
const Authentication = React.lazy(() => import("./containers/Authentication"));
const UserTypeSwitcher = React.lazy(
  () => import("./containers/UserTypeSwitcher"),
);
const Overview = React.lazy(() => import("./containers/Overview"));
const Profile = React.lazy(() => import("./containers/Profile"));
const Vehicles = React.lazy(() => import("./containers/Vehicles"));
const Vehicle = React.lazy(() => import("./containers/Vehicle"));
const Drivers = React.lazy(() => import("./containers/Drivers"));
const DriverForm = React.lazy(() => import("./containers/DriverForm"));
const VehicleForm = React.lazy(() => import("./containers/VehicleForm"));
const ReminderForm = React.lazy(() => import("./containers/ReminderForm"));
const AccountingForm = React.lazy(() => import("./containers/AccountingForm"));
const OwnerReport = React.lazy(() => import("./containers/OwnerReport"));
const DriverReport = React.lazy(() => import("./containers/DriverReport"));
const Subscriptions = React.lazy(() => import("./containers/Subscriptions"));
const PrivacyPolicy = React.lazy(() => import("./containers/PrivacyPolicy"));

const { useBreakpoint } = Grid;
const { Content } = Layout;

const App: React.FC = () => {
  const screens = useBreakpoint();
  const { authUser, loadingAuth, userType } = useApp();
  const location = useLocation();

  return (
    <ConfigProvider
      theme={{
        components: {
          Layout: {
            headerColor: "#000",
            headerBg: "#ff9900",
            bodyBg: "#f3b423",
          },
          Segmented: {
            itemSelectedBg: "#70b354",
          },
        },
        token: {
          fontFamily: '"Balsamiq Sans", sans-serif',
          colorPrimary: "#38761d",
        },
      }}
    >
      <Layout className="layout" style={{ height: "100%", minHeight: "100vh" }}>
        <SplashScreen loading={loadingAuth}>
          {authUser?.user && <SideMenu />}
          <Layout>
            <Notification />
            <Header />
            <Content
              style={{ padding: !screens.lg ? "15px 20px" : "15px 50px" }}
            >
              {authUser?.user ? (
                <>
                  {location.pathname !== Url.ProfileRoute &&
                    location.pathname !== Url.SubscriptionsRoute &&
                    location.pathname !== Url.PrivacyPolicyRoute && (
                      <UserTypeSwitcher />
                    )}
                  <Breadcrumbs />
                  <Suspense fallback={<Loader loading={true} />}>
                    <Routes>
                      <Route
                        path={Url.BaseRoute}
                        element={
                          <Navigate
                            to={
                              userType === UserType.Driver
                                ? Url.DriverRoute
                                : Url.OwnerRoute
                            }
                            replace
                          />
                        }
                      />
                      <Route path={Url.ProfileRoute} element={<Profile />} />
                      <Route
                        path={Url.SubscriptionsRoute}
                        element={<Subscriptions />}
                      />
                      <Route path={Url.DriverRoute} element={<Vehicles />} />
                      <Route path={Url.OwnerRoute} element={<Overview />} />
                      <Route
                        path={Url.OwnerVehiclesRoute}
                        element={<Vehicles />}
                      />
                      <Route
                        path={Url.DriverVehicleRoute}
                        element={<Vehicle />}
                      />
                      <Route
                        path={Url.OwnerVehicleRoute}
                        element={<Vehicle />}
                      />
                      <Route path={Url.DriversRoute} element={<Drivers />} />
                      <Route
                        path={Url.AddDriverRoute}
                        element={<DriverForm />}
                      />
                      <Route
                        path={Url.UpdateDriverRoute}
                        element={<DriverForm />}
                      />
                      <Route
                        path={Url.AddVehicleRoute}
                        element={<VehicleForm />}
                      />
                      <Route
                        path={Url.UpdateVehicleRoute}
                        element={<VehicleForm />}
                      />
                      <Route
                        path={Url.AddReminderRoute}
                        element={<ReminderForm />}
                      />
                      <Route
                        path={Url.AddOwnerAccountingRoute}
                        element={<AccountingForm />}
                      />
                      <Route
                        path={Url.AddDriverAccountingRoute}
                        element={<AccountingForm />}
                      />
                      <Route
                        path={Url.OwnerReportRoute}
                        element={<OwnerReport />}
                      />
                      <Route
                        path={Url.DriverReportRoute}
                        element={<DriverReport />}
                      />
                      <Route
                        path={Url.PrivacyPolicyRoute}
                        element={<PrivacyPolicy />}
                      />
                      {/* Redirect all other routes to BaseRoute */}
                      <Route
                        path="*"
                        element={<Navigate to={Url.BaseRoute} replace />}
                      />
                    </Routes>
                  </Suspense>
                </>
              ) : authUser ? (
                <Profile />
              ) : (
                <Routes>
                  <Route
                    path={Url.PrivacyPolicyRoute}
                    element={<PrivacyPolicy />}
                  />
                  <Route path="*" element={<Authentication />} />
                </Routes>
              )}
            </Content>
          </Layout>
        </SplashScreen>
      </Layout>
    </ConfigProvider>
  );
};

export default App;
