import React, { useEffect } from "react";
import { onMessageListener } from "tasit-defteri-firebase-functions";
import { Button, notification, Space, message } from "antd";
import { useApp } from "../../context/app.context";

const Notification: React.FC = () => {
  const [api, contextHolder] = notification.useNotification();
  const { authUser, fetchDriverRequests, fetchDrivers } = useApp();

  const openNotification = async ({
    notification,
    data,
  }: {
    notification: any;
    data: any;
  }) => {
    const key = `open${Date.now()}`;
    const btn = (
      <Space>
        <Button type="link" size="small" onClick={() => api.destroy()}>
          Bildirimleri kapat
        </Button>
      </Space>
    );
    api.open({
      message: notification?.title,
      description: notification?.body,
      btn,
      key,
    });

    if (data?.type === "driverRequest" && authUser?.user) {
      await fetchDriverRequests({
        phone: authUser.phone,
      });
    }

    if (data?.type === "driverRequestSubmission") {
      await fetchDrivers({
        vehicleId: data?.vehicleId,
      });
    }
  };

  const handleIncomingMessage = async () => {
    try {
      const payload = await onMessageListener();
      openNotification(payload as any);
    } catch (err: any) {
      message.error("Bildirim hatası: ", err);
    }
  };

  // Use useEffect to call handleIncomingMessage when the component mounts
  useEffect(() => {
    handleIncomingMessage();
    // eslint-disable-next-line
  }, []);

  return <>{contextHolder}</>;
};

export default Notification;
