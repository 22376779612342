import { message } from "antd";
import {
  createSubscription,
  ICreateSubscriptionRequest,
  getCustomerPortal,
  IGetCustomerPortalRequest,
  getSubscriptions,
  ISubscription,
  hasValidSubscription,
  IHasValidSubscriptionRequest,
} from "tasit-defteri-firebase-functions";
import { convertDateFields } from "../../utils/convertDateFields";

export async function createSubscriptionRequest(
  payload: ICreateSubscriptionRequest,
): Promise<string | undefined> {
  try {
    const result = await createSubscription(payload);
    return result;
  } catch (error: any) {
    message.error(error.message);
  }
}

export async function getCustomerPortalRequest(
  payload: IGetCustomerPortalRequest,
): Promise<string | undefined> {
  try {
    const result = await getCustomerPortal(payload);
    return result;
  } catch (error: any) {
    message.error(error.message);
  }
}

export async function getSubscriptionsRequest(): Promise<ISubscription[]> {
  try {
    const result = await getSubscriptions();
    convertDateFields(result);
    return result;
  } catch (error: any) {
    message.error(error.message);
    return [];
  }
}

export async function hasValidSubscriptionRequest(
  payload: IHasValidSubscriptionRequest,
): Promise<boolean> {
  try {
    const result = await hasValidSubscription(payload);
    return result;
  } catch (error: any) {
    message.error(error.message);
    return false;
  }
}
