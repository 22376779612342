import { message } from "antd";
import {
  startDriving,
  IStartDrivingRequest,
  stopDriving,
  IStopDrivingRequest,
} from "tasit-defteri-firebase-functions";

export async function startDrivingRequest(
  payload: IStartDrivingRequest,
): Promise<Date | undefined> {
  try {
    const result = await startDriving(payload);
    return result;
  } catch (error: any) {
    message.error(error.message);
  }
}

export async function stopDrivingRequest(
  payload: IStopDrivingRequest,
): Promise<Date | undefined> {
  try {
    const result = await stopDriving(payload);
    return result;
  } catch (error: any) {
    message.error(error.message);
  }
}
