export enum UserType {
  Driver = "driver",
  Owner = "owner",
}

export const userTypeData = [
  {
    value: UserType.Driver,
    label: "Şoför",
  },
  {
    value: UserType.Owner,
    label: "Araç Sahibi",
  },
];
