import React, { useState } from "react";
import { Button, Col, Badge, List } from "antd";
import { useApp } from "../../../../context/app.context";
import Loader from "../../../../components/Loader";
import { BellOutlined } from "@ant-design/icons";
import {
  DriverRequestStatus,
  ISubmitDriverRequest,
} from "tasit-defteri-firebase-functions";

const NotificationList: React.FC = () => {
  const [notificationListVisibility, setNotificationListVisibility] =
    useState(false);

  const {
    driverRequests,
    loadingDriverRequests,
    submitDriverRequest,
    loadingSubmitDriverRequest,
  } = useApp();

  const toggleNotificationList = () => {
    setNotificationListVisibility(!notificationListVisibility);
  };

  const handleDriverRequestSubmission = async (
    payload: ISubmitDriverRequest,
  ) => {
    await submitDriverRequest(payload);
    toggleNotificationList();
  };

  return (
    <>
      <Loader loading={loadingSubmitDriverRequest} />
      <Col className="notification-header">
        <Button
          type="text"
          icon={
            <Badge size="small" count={driverRequests.length}>
              <BellOutlined
                style={{
                  fontSize: "24px",
                }}
              />
            </Badge>
          }
          onClick={toggleNotificationList}
        />
        {notificationListVisibility && (
          <List
            className="notification-list"
            bordered
            loading={loadingDriverRequests}
            locale={{ emptyText: "Yeni bildirim bulunamadı" }}
            itemLayout="horizontal"
            dataSource={driverRequests}
            renderItem={({ vehicle, id }) => (
              <List.Item
                actions={[
                  <Button
                    type="primary"
                    size="small"
                    onClick={() =>
                      handleDriverRequestSubmission({
                        id,
                        status: DriverRequestStatus.Approved,
                      })
                    }
                  >
                    Onayla
                  </Button>,
                  <Button
                    type="link"
                    size="small"
                    onClick={() =>
                      handleDriverRequestSubmission({
                        id,
                        status: DriverRequestStatus.Rejected,
                      })
                    }
                  >
                    Reddet
                  </Button>,
                ]}
              >
                <List.Item.Meta
                  title="Yeni Şoför İsteği"
                  description={`${vehicle?.plateNo} plakalı araca şoför olarak eklendiniz.`}
                />
              </List.Item>
            )}
          />
        )}
      </Col>
    </>
  );
};

export default NotificationList;
