import React from "react";
import { Button, Layout, Row, Col, Grid } from "antd";
import { useNavigate } from "react-router-dom";
import { useApp } from "../../context/app.context";
import { Url } from "../../constants/urls";
import { MenuOutlined } from "@ant-design/icons";
import NotificationList from "./components/NotificationList";
import "./index.css";

const { Header } = Layout;
const { useBreakpoint } = Grid;

const Head: React.FC = () => {
  const { authUser, sideMenuCollapsed, toggleSideMenuCollapsed } = useApp();
  const navigate = useNavigate();
  const screens = useBreakpoint();

  return (
    <Header
      className="header-container"
      style={{ padding: !screens.lg ? "0 20px" : "" }}
    >
      <Row
        className="header-row"
        justify={authUser?.user ? "space-between" : "center"}
      >
        {authUser?.user && (
          <Button
            type="text"
            icon={
              <MenuOutlined
                style={{
                  fontSize: "24px",
                }}
              />
            }
            onClick={toggleSideMenuCollapsed}
          />
        )}
        <Col
          className={
            !sideMenuCollapsed && !screens.lg
              ? "header-title-collapsed"
              : "header-title"
          }
          role="button"
          tabIndex={0}
          onClick={() => navigate(Url.BaseRoute)}
        >
          <span style={{ color: "#38761d" }}>Taşıt</span>Defteri
        </Col>
        {authUser?.user && <NotificationList />}
      </Row>
    </Header>
  );
};

export default Head;
