import React from "react";
import { Layout, Menu, Row, Grid } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import { useApp } from "../../context/app.context";
import { Url } from "../../constants/urls";
import {
  HomeOutlined,
  UserOutlined,
  LogoutOutlined,
  CreditCardOutlined,
  SecurityScanOutlined,
} from "@ant-design/icons";
import Logo from "../../images/logo.png";
import "./index.css";

const { Sider } = Layout;
const { useBreakpoint } = Grid;

const SideMenu: React.FC = () => {
  const { signOut, sideMenuCollapsed } = useApp();
  const screens = useBreakpoint();
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Sider
      trigger={null}
      collapsedWidth={0}
      collapsible
      collapsed={sideMenuCollapsed}
      width={screens.xs ? 140 : 200}
    >
      <Row justify="center" align="middle">
        <img alt="logo" width="100px" src={Logo} />
      </Row>
      <Menu
        theme="dark"
        mode="inline"
        className="side-menu"
        defaultSelectedKeys={[Url.BaseRoute]}
        selectedKeys={
          location.pathname === Url.ProfileRoute
            ? [Url.ProfileRoute]
            : location.pathname === Url.SubscriptionsRoute
            ? [Url.SubscriptionsRoute]
            : location.pathname === Url.PrivacyPolicyRoute
            ? [Url.PrivacyPolicyRoute]
            : [Url.BaseRoute]
        }
        onSelect={({ key }) => {
          if (key === "logout") {
            signOut();
          } else {
            navigate(key);
          }
        }}
        items={[
          {
            key: Url.BaseRoute,
            icon: <HomeOutlined />,
            label: "Ana Sayfa",
          },
          {
            key: Url.ProfileRoute,
            icon: <UserOutlined />,
            label: "Hesabım",
          },
          {
            key: Url.SubscriptionsRoute,
            icon: <CreditCardOutlined />,
            label: "Aboneliklerim",
          },
          {
            key: Url.PrivacyPolicyRoute,
            icon: <SecurityScanOutlined />,
            label: "Gizlilik",
          },
          {
            key: "logout",
            icon: <LogoutOutlined />,
            label: "Çıkış Yap",
          },
        ]}
      />
    </Sider>
  );
};

export default SideMenu;
