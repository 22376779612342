import React, { useState, useEffect } from "react";
import { Row } from "antd";
import Logo from "../../images/logo.png";
import Logo1 from "../../images/1.png";
import Logo2 from "../../images/2.png";
import Logo3 from "../../images/3.png";
import Logo4 from "../../images/4.png";
import "./index.css";

interface LoaderProps {
  loading: boolean;
  children?: React.ReactNode;
}

const Loader: React.FC<LoaderProps> = ({ loading, children }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const images = [Logo, Logo1, Logo2, Logo3, Logo4];

  useEffect(() => {
    let interval: NodeJS.Timeout;

    if (loading) {
      // Set up the interval to switch images every 300ms
      interval = setInterval(() => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
      }, 300);
    }

    // Clear the interval when the component is unmounted or loading is false
    return () => clearInterval(interval);
  }, [loading, images.length]);

  if (loading) {
    return (
      <Row className="loader-container" justify="center" align="middle">
        <img alt="logo" width="250px" src={images[currentImageIndex]} />
      </Row>
    );
  }

  return <>{children}</>;
};

export default Loader;
