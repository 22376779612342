import { UserType } from "../containers/UserTypeSwitcher/types";

export class UserTypeStorage {
  private static readonly storageKey = "user_type";

  public static store(userType: UserType): void {
    localStorage.setItem(this.storageKey, userType);
  }

  public static clear(): void {
    localStorage.removeItem(this.storageKey);
  }

  public static get(): UserType | null {
    try {
      const storedUserType = localStorage.getItem(this.storageKey);
      return storedUserType !== null ? (storedUserType as UserType) : null;
    } catch (err) {
      return null;
    }
  }
}
