import {
  createContext,
  useCallback,
  useContext,
  useState,
  useEffect,
} from "react";
import { setToEndOfDay, setToStartOfDay } from "../utils/dayjs";
import { useNavigate, useLocation } from "react-router-dom";
import dayjs from "dayjs";
import { Url } from "../constants/urls";
import {
  ICreateOrUpdateUserRequest,
  ICreateOrUpdateVehicleRequest,
  IDeleteVehicleRequest,
  IReminder,
  ICreateReminderRequest,
  IReminderType,
  IAccountingType,
  ICreateAccountingRequest,
  IGetDriversByVehicleIdRequest,
  ICreateOrUpdateDriverRequest,
  IDeleteDriverRequest,
  IStartDrivingRequest,
  IStopDrivingRequest,
  IGetDriverRequestsByPhone,
  IDriverRequest,
  ISubmitDriverRequest,
  DriverRequestStatus,
  IGetVehicleByIdRequest,
  IVehiclesReportSummary,
  IGetVehicleReportSummaryRequest,
  IGetVehiclesReportSummaryRequest,
  IVehicleReport,
  IGetVehicleReportRequest,
  IDriverReport,
  IGetDriverReportRequest,
  IDailyAccounting,
  IGetDailyAccountingRequest,
  ICreateSubscriptionRequest,
  IGetCustomerPortalRequest,
  ISubscription,
  IHasValidSubscriptionRequest,
} from "tasit-defteri-firebase-functions";
import {
  IOtpRequest,
  ISignInRequest,
  IAuthResponse,
} from "../containers/Authentication/types";
import {
  getAuthRequest,
  otpRequest,
  signInRequest,
  signOutRequest,
} from "../services/requests/authentication";
import {
  getUserByPhoneRequest,
  createOrUpdateUserRequest,
} from "../services/requests/user";
import {
  createOrUpdateVehicleRequest,
  deleteVehicleRequest,
  getVehicleByIdRequest,
} from "../services/requests/vehicle";
import {
  getRemindersByUserIdRequest,
  getReminderTypesRequest,
  createReminderRequest,
} from "../services/requests/reminder";
import {
  getAccountingTypesRequest,
  createAccountingRequest,
  getDailyAccountingRequest,
} from "../services/requests/accounting";
import {
  getDriversByVehicleIdRequest,
  createOrUpdateDriverRequest,
  deleteDriverRequest,
  getDriverRequestsByPhoneRequest,
  submitDriverRequestRequest,
} from "../services/requests/driver";
import {
  startDrivingRequest,
  stopDrivingRequest,
} from "../services/requests/drivingHistory";
import {
  getFCMTokenRequest,
  createFCMTokenRequest,
} from "../services/requests/fcmToken";
import {
  getVehicleReportSummaryRequest,
  getVehiclesReportSummaryRequest,
  getVehicleReportRequest,
  getDriverReportRequest,
} from "../services/requests/report";
import {
  createSubscriptionRequest,
  getCustomerPortalRequest,
  getSubscriptionsRequest,
  hasValidSubscriptionRequest,
} from "../services/requests/payment";
import { UserType } from "../containers/UserTypeSwitcher/types";

import { UserTypeStorage } from "../services/UserTypeStorage";

interface AppContextState {
  authUser: IAuthResponse | null;
  verificationId?: string | null;
  userType: UserType;
  sideMenuCollapsed: boolean;
  getAuth: () => void;
  sendOtp: (payload: IOtpRequest) => void;
  signIn: (payload: ISignInRequest) => void;
  signOut: () => void;
  updateUserType: (userType: UserType) => void;
  toggleSideMenuCollapsed: () => void;
  createOrUpdateUser: (payload: ICreateOrUpdateUserRequest) => void;
  createVehicle: (payload: ICreateOrUpdateVehicleRequest) => void;
  updateVehicle: (payload: ICreateOrUpdateVehicleRequest) => void;
  deleteVehicle: (payload: IDeleteVehicleRequest) => void;
  reminders: IReminder[];
  reminderTypes: IReminderType[];
  fetchReminders: () => void;
  fetchReminderTypes: () => void;
  createReminder: (payload: ICreateReminderRequest) => void;
  accountingTypes: IAccountingType[];
  fetchAccountingTypes: () => void;
  createAccounting: (payload: ICreateAccountingRequest) => void;
  drivers: any[];
  fetchDrivers: (payload: IGetDriversByVehicleIdRequest) => void;
  createOrUpdateDriver: (payload: ICreateOrUpdateDriverRequest) => void;
  deleteDriver: (payload: IDeleteDriverRequest) => void;
  startDriving: (payload: IStartDrivingRequest) => void;
  stopDriving: (payload: IStopDrivingRequest) => void;
  driverRequests: IDriverRequest[];
  fetchDriverRequests: (payload: IGetDriverRequestsByPhone) => void;
  submitDriverRequest: (payload: ISubmitDriverRequest) => void;
  fetchVehicleById: (payload: IGetVehicleByIdRequest) => void;
  vehicleReportSummary: IVehiclesReportSummary[];
  vehiclesReportSummary: IVehiclesReportSummary[];
  fetchVehicleReportSummary: (payload: IGetVehicleReportSummaryRequest) => void;
  fetchVehiclesReportSummary: (
    payload: IGetVehiclesReportSummaryRequest,
  ) => void;
  vehicleReport: IVehicleReport[];
  fetchVehicleReport: (payload: IGetVehicleReportRequest) => void;
  cleanVehicleReport: () => void;
  driverReport: IDriverReport[];
  fetchDriverReport: (payload: IGetDriverReportRequest) => void;
  cleanDriverReport: () => void;
  dailyAccounting: IDailyAccounting | null;
  fetchDailyAccounting: (payload: IGetDailyAccountingRequest) => void;
  createSubscription: (payload: ICreateSubscriptionRequest) => void;
  checkoutUrl: string | undefined | null;
  getCustomerPortal: (payload: IGetCustomerPortalRequest) => void;
  customerPortalUrl: string | undefined | null;
  subscriptions: ISubscription[];
  fetchSubscriptions: () => void;
  hasValidSubscription: (
    payload: IHasValidSubscriptionRequest,
  ) => Promise<boolean>;
  loadingAuth: boolean;
  loadingOtp: boolean;
  loadingSignIn: boolean;
  loadingUser: boolean;
  loadingVehicle: boolean;
  loadingDeleteVehicle: boolean;
  loadingReminder: boolean;
  loadingReminderType: boolean;
  loadingAccounting: boolean;
  loadingAccountingType: boolean;
  loadingDrivers: boolean;
  loadingCreateOrUpdateDriver: boolean;
  loadingDeleteDriver: boolean;
  loadingDriving: boolean;
  loadingDriverRequests: boolean;
  loadingSubmitDriverRequest: boolean;
  loadingVehicleReportSummary: boolean;
  loadingVehiclesReportSummary: boolean;
  loadingVehicleReport: boolean;
  loadingDriverReport: boolean;
  loadingDailyAccounting: boolean;
  loadingCreateSubscription: boolean;
  loadingGetCustomerPortal: boolean;
  loadingSubscriptions: boolean;
  loadingHasValidSubscription: boolean;
}

const defaultAppContext: AppContextState = {
  authUser: null,
  verificationId: null,
  userType: UserTypeStorage.get() || UserType.Driver,
  sideMenuCollapsed: true,
  getAuth: () => {},
  sendOtp: () => {},
  signIn: () => {},
  signOut: () => {},
  updateUserType: () => {},
  toggleSideMenuCollapsed: () => {},
  createOrUpdateUser: () => {},
  createVehicle: () => {},
  updateVehicle: () => {},
  deleteVehicle: () => {},
  reminders: [],
  reminderTypes: [],
  fetchReminders: () => {},
  fetchReminderTypes: () => {},
  createReminder: () => {},
  accountingTypes: [],
  fetchAccountingTypes: () => {},
  createAccounting: () => {},
  drivers: [],
  fetchDrivers: () => {},
  createOrUpdateDriver: () => {},
  deleteDriver: () => {},
  startDriving: () => {},
  stopDriving: () => {},
  driverRequests: [],
  fetchDriverRequests: () => {},
  submitDriverRequest: () => {},
  fetchVehicleById: () => {},
  vehicleReportSummary: [],
  vehiclesReportSummary: [],
  fetchVehicleReportSummary: () => {},
  fetchVehiclesReportSummary: () => {},
  vehicleReport: [],
  fetchVehicleReport: () => {},
  cleanVehicleReport: () => {},
  driverReport: [],
  fetchDriverReport: () => {},
  cleanDriverReport: () => {},
  dailyAccounting: null,
  fetchDailyAccounting: () => {},
  createSubscription: () => {},
  checkoutUrl: null,
  getCustomerPortal: () => {},
  subscriptions: [],
  fetchSubscriptions: () => {},
  customerPortalUrl: null,
  hasValidSubscription: async () => false,
  loadingAuth: true,
  loadingOtp: false,
  loadingSignIn: false,
  loadingUser: false,
  loadingVehicle: false,
  loadingDeleteVehicle: false,
  loadingReminder: false,
  loadingReminderType: false,
  loadingAccounting: false,
  loadingAccountingType: false,
  loadingDrivers: false,
  loadingCreateOrUpdateDriver: false,
  loadingDeleteDriver: false,
  loadingDriving: false,
  loadingDriverRequests: false,
  loadingSubmitDriverRequest: false,
  loadingVehicleReportSummary: false,
  loadingVehiclesReportSummary: false,
  loadingVehicleReport: false,
  loadingDriverReport: false,
  loadingDailyAccounting: false,
  loadingCreateSubscription: false,
  loadingGetCustomerPortal: false,
  loadingSubscriptions: false,
  loadingHasValidSubscription: false,
};

const useLoadingState = (initialState: boolean) => {
  const [loading, setLoading] = useState(initialState);

  const updateLoading = useCallback((value: boolean) => {
    setLoading(value);
  }, []);

  return [loading, updateLoading] as const;
};

const useAppContext = (props: AppContextState): AppContextState => {
  const navigate = useNavigate();
  const location = useLocation();
  const [verificationId, setVerificationId] = useState(props.verificationId);
  const [authUser, setAuthUser] = useState(props.authUser);
  const [userType, setUserType] = useState(props.userType);
  const [sideMenuCollapsed, setSideMenuCollapsed] = useState(
    props.sideMenuCollapsed,
  );
  const [reminders, setReminders] = useState(props.reminders);
  const [reminderTypes, setReminderTypes] = useState(props.reminderTypes);
  const [accountingTypes, setAccountingTypes] = useState(props.accountingTypes);
  const [drivers, setDrivers] = useState(props.drivers);
  const [driverRequests, setDriverRequests] = useState(props.driverRequests);
  const [vehicleReportSummary, setVehicleReportSummary] = useState(
    props.vehicleReportSummary,
  );
  const [vehiclesReportSummary, setVehiclesReportSummary] = useState(
    props.vehiclesReportSummary,
  );
  const [vehicleReport, setVehicleReport] = useState(props.vehicleReport);
  const [driverReport, setDriverReport] = useState(props.driverReport);
  const [dailyAccounting, setDailyAccounting] = useState(props.dailyAccounting);
  const [checkoutUrl, setCheckoutUrl] = useState(props.checkoutUrl);
  const [customerPortalUrl, setCustomerPortalUrl] = useState(
    props.customerPortalUrl,
  );
  const [subscriptions, setSubscriptions] = useState(props.subscriptions);

  const [loadingAuth, updateLoadingAuth] = useLoadingState(props.loadingAuth);
  const [loadingOtp, updateLoadingOtp] = useLoadingState(props.loadingOtp);
  const [loadingSignIn, updateLoadingSignIn] = useLoadingState(
    props.loadingSignIn,
  );
  const [loadingUser, updateLoadingUser] = useLoadingState(props.loadingUser);
  const [loadingVehicle, updateLoadingVehicle] = useLoadingState(
    props.loadingVehicle,
  );
  const [loadingDeleteVehicle, updateLoadingDeleteVehicle] = useLoadingState(
    props.loadingDeleteVehicle,
  );
  const [loadingReminder, updateLoadingReminder] = useLoadingState(
    props.loadingReminder,
  );
  const [loadingReminderType, updateLoadingReminderType] = useLoadingState(
    props.loadingReminderType,
  );
  const [loadingAccountingType, updateLoadingAccountingType] = useLoadingState(
    props.loadingAccountingType,
  );
  const [loadingAccounting, updateLoadingAccounting] = useLoadingState(
    props.loadingAccounting,
  );
  const [loadingDrivers, updateLoadingDrivers] = useLoadingState(
    props.loadingDrivers,
  );
  const [loadingCreateOrUpdateDriver, updateLoadingCreateOrUpdateDriver] =
    useLoadingState(props.loadingCreateOrUpdateDriver);
  const [loadingDeleteDriver, updateLoadingDeleteDriver] = useLoadingState(
    props.loadingDeleteDriver,
  );
  const [loadingDriving, updateLoadingDriving] = useLoadingState(
    props.loadingDriving,
  );
  const [loadingDriverRequests, updateLoadingDriverRequests] = useLoadingState(
    props.loadingDriverRequests,
  );
  const [loadingSubmitDriverRequest, updateLoadingSubmitDriverRequest] =
    useLoadingState(props.loadingSubmitDriverRequest);
  const [loadingVehicleReportSummary, updateLoadingVehicleReportSummary] =
    useLoadingState(props.loadingVehicleReportSummary);
  const [loadingVehiclesReportSummary, updateLoadingVehiclesReportSummary] =
    useLoadingState(props.loadingVehiclesReportSummary);
  const [loadingVehicleReport, updateLoadingVehicleReport] = useLoadingState(
    props.loadingVehicleReport,
  );
  const [loadingDriverReport, updateLoadingDriverReport] = useLoadingState(
    props.loadingDriverReport,
  );
  const [loadingDailyAccounting, updateLoadingDailyAccounting] =
    useLoadingState(props.loadingDailyAccounting);
  const [loadingCreateSubscription, updateLoadingCreateSubscription] =
    useLoadingState(props.loadingCreateSubscription);
  const [loadingGetCustomerPortal, updateLoadingGetCustomerPortal] =
    useLoadingState(props.loadingGetCustomerPortal);
  const [loadingSubscriptions, updateLoadingSubscriptions] = useLoadingState(
    props.loadingSubscriptions,
  );
  const [loadingHasValidSubscription, updateLoadingHasValidSubscription] =
    useLoadingState(props.loadingHasValidSubscription);

  const updateVerificationId = useCallback(
    (verificationId: AppContextState["verificationId"]) => {
      setVerificationId(verificationId);
    },
    [],
  );

  const updateAuthUser = useCallback(
    (authUser: AppContextState["authUser"]) => {
      setAuthUser(authUser);
    },
    [],
  );

  const updateUserType = useCallback(
    (userType: AppContextState["userType"]) => {
      setUserType(userType);
      UserTypeStorage.store(userType);
      navigate(userType === UserType.Driver ? Url.DriverRoute : Url.OwnerRoute);
    },
    [navigate],
  );

  const updateReminders = useCallback(
    (reminders: AppContextState["reminders"]) => {
      setReminders(reminders);
    },
    [],
  );

  const updateReminderTypes = useCallback(
    (reminderTypes: AppContextState["reminderTypes"]) => {
      setReminderTypes(reminderTypes);
    },
    [],
  );

  const updateAccountingTypes = useCallback(
    (accountingTypes: AppContextState["accountingTypes"]) => {
      setAccountingTypes(accountingTypes);
    },
    [],
  );

  const updateDrivers = useCallback((drivers: AppContextState["drivers"]) => {
    setDrivers(drivers);
  }, []);

  const updateDriverRequests = useCallback(
    (driverRequests: AppContextState["driverRequests"]) => {
      setDriverRequests(driverRequests);
    },
    [],
  );

  const updateVehicleReportSummary = useCallback(
    (vehicleReportSummary: AppContextState["vehicleReportSummary"]) => {
      setVehicleReportSummary(vehicleReportSummary);
    },
    [],
  );

  const updateVehiclesReportSummary = useCallback(
    (vehiclesReportSummary: AppContextState["vehiclesReportSummary"]) => {
      setVehiclesReportSummary(vehiclesReportSummary);
    },
    [],
  );

  const updateVehicleReport = useCallback(
    (vehicleReport: AppContextState["vehicleReport"]) => {
      setVehicleReport(vehicleReport);
    },
    [],
  );

  const updateDriverReport = useCallback(
    (driverReport: AppContextState["driverReport"]) => {
      setDriverReport(driverReport);
    },
    [],
  );

  const updateDailyAccounting = useCallback(
    (dailyAccounting: AppContextState["dailyAccounting"]) => {
      setDailyAccounting(dailyAccounting);
    },
    [],
  );

  const updateCheckoutUrl = useCallback(
    (checkoutUrl: AppContextState["checkoutUrl"]) => {
      setCheckoutUrl(checkoutUrl);
    },
    [],
  );

  const updateCustomerPortalUrl = useCallback(
    (customerPortalUrl: AppContextState["customerPortalUrl"]) => {
      setCustomerPortalUrl(customerPortalUrl);
    },
    [],
  );

  const updateSubscriptions = useCallback(
    (subscriptions: AppContextState["subscriptions"]) => {
      setSubscriptions(subscriptions);
    },
    [],
  );

  const toggleSideMenuCollapsed = () => {
    setSideMenuCollapsed(!sideMenuCollapsed);
  };

  const requestNotificationPermission = async (payload: { phone: string }) => {
    console.log("Requesting permission...");
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      console.log("Notification permission granted.");
      const token = await getFCMTokenRequest();
      if (token) {
        await createFCMTokenRequest({
          token,
          phone: payload.phone,
        });
      }
    }
  };

  const getAuth = useCallback(async () => {
    updateLoadingAuth(true);
    try {
      const auth = await getAuthRequest();
      auth?.onAuthStateChanged(async (user: any) => {
        if (user) {
          const existingUser = await getUserByPhoneRequest({
            phone: user.phoneNumber,
          });
          await requestNotificationPermission({
            phone: user.phoneNumber,
          });

          await fetchDriverRequests({
            phone: user.phoneNumber,
          });

          updateAuthUser({
            phone: user.phoneNumber,
            user: existingUser || undefined,
          });
        } else {
          updateAuthUser(defaultAppContext.authUser);
        }
        setTimeout(() => {
          updateLoadingAuth(false);
        }, 1000);
      });
    } catch (error) {
      throw error;
    }
    // eslint-disable-next-line
  }, [updateAuthUser, updateLoadingAuth]);

  const sendOtp = async (payload: IOtpRequest) => {
    updateLoadingOtp(true);
    try {
      const data = await otpRequest(payload);
      updateVerificationId(data);
    } catch (error) {
      throw error;
    } finally {
      updateLoadingOtp(false);
    }
  };

  const signIn = async (payload: ISignInRequest) => {
    updateLoadingSignIn(true);
    try {
      const data = await signInRequest(payload);
      if (data) {
        const existingUser = await getUserByPhoneRequest({
          phone: data.user.phoneNumber,
        });
        updateAuthUser({
          phone: data.user.phoneNumber,
          user: existingUser || undefined,
        });
        updateVerificationId(defaultAppContext.verificationId);
      }
    } catch (error) {
      throw error;
    } finally {
      updateLoadingSignIn(false);
    }
  };

  const signOut = async () => {
    try {
      await signOutRequest();
      updateAuthUser(defaultAppContext.authUser);
      updateVerificationId(defaultAppContext.verificationId);
      navigate(Url.BaseRoute);
    } catch (error) {
      throw error;
    }
  };

  const createOrUpdateUser = async (payload: ICreateOrUpdateUserRequest) => {
    updateLoadingUser(true);
    try {
      const data = await createOrUpdateUserRequest(payload);
      if (data) {
        updateAuthUser({
          phone: data.phone,
          user: data,
        });
      }
    } catch (error) {
      throw error;
    } finally {
      updateLoadingUser(false);
    }
  };

  const createVehicle = async (payload: ICreateOrUpdateVehicleRequest) => {
    updateLoadingVehicle(true);
    try {
      const data = await createOrUpdateVehicleRequest(payload);
      if (data && authUser?.user) {
        updateAuthUser({
          ...authUser,
          user: {
            ...authUser.user,
            ownedVehicles: [...(authUser.user.ownedVehicles || []), data],
            drivingVehicles: [...(authUser.user.drivingVehicles || []), data],
          },
        });
        navigate(Url.OwnerVehiclesRoute);
      }
    } catch (error) {
      throw error;
    } finally {
      updateLoadingVehicle(false);
    }
  };

  const updateVehicle = async (payload: ICreateOrUpdateVehicleRequest) => {
    updateLoadingVehicle(true);
    try {
      const data = await createOrUpdateVehicleRequest(payload);
      if (data && authUser?.user) {
        updateAuthUser({
          ...authUser,
          user: {
            ...authUser.user,
            ownedVehicles: [
              ...(authUser.user.ownedVehicles || []).filter(
                ({ id }) => id !== data.id,
              ),
              data,
            ],
            drivingVehicles: [
              ...(authUser.user.drivingVehicles || []).filter(
                ({ id }) => id !== data.id,
              ),
              data,
            ],
          },
        });
        navigate(Url.OwnerVehiclesRoute + data.id);
      }
    } catch (error) {
      throw error;
    } finally {
      updateLoadingVehicle(false);
    }
  };

  const deleteVehicle = async (payload: IDeleteVehicleRequest) => {
    updateLoadingDeleteVehicle(true);
    try {
      await deleteVehicleRequest(payload);
      if (authUser?.user) {
        updateAuthUser({
          ...authUser,
          user: {
            ...authUser.user,
            ownedVehicles: [
              ...(authUser.user.ownedVehicles || []).filter(
                ({ id }) => id !== payload.id,
              ),
            ],
            drivingVehicles: [
              ...(authUser.user.drivingVehicles || []).filter(
                ({ id }) => id !== payload.id,
              ),
            ],
          },
        });
        navigate(Url.OwnerVehiclesRoute);
      }
    } catch (error) {
      throw error;
    } finally {
      updateLoadingDeleteVehicle(false);
    }
  };

  const fetchReminders = async () => {
    updateLoadingReminder(true);
    try {
      if (authUser?.user) {
        const data = await getRemindersByUserIdRequest({
          userId: authUser.user.id,
        });
        updateReminders(data);
      }
    } catch (error) {
      throw error;
    } finally {
      updateLoadingReminder(false);
    }
  };

  const fetchReminderTypes = async () => {
    updateLoadingReminderType(true);
    try {
      const data = await getReminderTypesRequest();
      updateReminderTypes(data);
    } catch (error) {
      throw error;
    } finally {
      updateLoadingReminderType(false);
    }
  };

  const createReminder = async (payload: ICreateReminderRequest) => {
    updateLoadingReminder(true);
    try {
      if (authUser?.user) {
        await createReminderRequest(payload);
        navigate(Url.OwnerVehiclesRoute + payload.vehicleId);
      }
    } catch (error) {
      throw error;
    } finally {
      updateLoadingReminder(false);
    }
  };

  const fetchAccountingTypes = async () => {
    updateLoadingAccountingType(true);
    try {
      const data = await getAccountingTypesRequest();
      updateAccountingTypes(data);
    } catch (error) {
      throw error;
    } finally {
      updateLoadingAccountingType(false);
    }
  };

  const createAccounting = async (payload: ICreateAccountingRequest) => {
    updateLoadingAccounting(true);
    try {
      if (authUser?.user) {
        await createAccountingRequest(payload);
        navigate(Url.OwnerVehiclesRoute + payload.vehicleId);
      }
    } catch (error) {
      throw error;
    } finally {
      updateLoadingAccounting(false);
    }
  };

  const fetchDrivers = async (payload: IGetDriversByVehicleIdRequest) => {
    updateLoadingDrivers(true);
    try {
      const data = await getDriversByVehicleIdRequest(payload);
      updateDrivers(data);
    } catch (error) {
      throw error;
    } finally {
      updateLoadingDrivers(false);
    }
  };

  const createOrUpdateDriver = async (
    payload: ICreateOrUpdateDriverRequest,
  ) => {
    updateLoadingCreateOrUpdateDriver(true);
    try {
      await createOrUpdateDriverRequest(payload);
      navigate(Url.OwnerVehiclesRoute + payload.vehicleId + "/drivers/");
    } catch (error) {
      throw error;
    } finally {
      updateLoadingCreateOrUpdateDriver(false);
    }
  };

  const deleteDriver = async (payload: IDeleteDriverRequest) => {
    updateLoadingDeleteDriver(true);
    try {
      await deleteDriverRequest(payload);
      navigate(Url.OwnerVehiclesRoute + payload.vehicleId + "/drivers/");
    } catch (error) {
      throw error;
    } finally {
      updateLoadingDeleteDriver(false);
    }
  };

  const startDriving = async (payload: IStartDrivingRequest) => {
    updateLoadingDriving(true);
    try {
      const data = await startDrivingRequest(payload);
      if (data && authUser?.user) {
        updateAuthUser({
          ...authUser,
          user: {
            ...authUser.user,
            drivingVehicles: authUser.user.drivingVehicles.map((vehicle) => {
              if (vehicle.id === payload.vehicleId) {
                return {
                  ...vehicle,
                  activeDriver: authUser?.user || null,
                  drivingStartDate: data,
                  updatedAt: data,
                };
              }
              return vehicle;
            }),
          },
        });
      }
    } catch (error) {
      throw error;
    } finally {
      updateLoadingDriving(false);
    }
  };

  const stopDriving = async (payload: IStopDrivingRequest) => {
    updateLoadingDriving(true);
    try {
      const data = await stopDrivingRequest(payload);
      if (data && authUser?.user) {
        updateAuthUser({
          ...authUser,
          user: {
            ...authUser.user,
            drivingVehicles: authUser.user.drivingVehicles.map((vehicle) => {
              if (vehicle.id === payload.vehicleId) {
                return {
                  ...vehicle,
                  activeDriver: null,
                  drivingStartDate: null,
                  updatedAt: data,
                };
              }
              return vehicle;
            }),
          },
        });
      }
      fetchDailyAccounting({
        userId: payload.userId,
        vehicleId: payload.vehicleId,
      });
    } catch (error) {
      throw error;
    } finally {
      updateLoadingDriving(false);
    }
  };

  const fetchDriverRequests = async (payload: IGetDriverRequestsByPhone) => {
    updateLoadingDriverRequests(true);
    try {
      const data = await getDriverRequestsByPhoneRequest(payload);
      updateDriverRequests(data);
    } catch (error) {
      throw error;
    } finally {
      updateLoadingDriverRequests(false);
    }
  };

  const submitDriverRequest = async (payload: ISubmitDriverRequest) => {
    updateLoadingSubmitDriverRequest(true);
    try {
      const data = await submitDriverRequestRequest(payload);
      updateDriverRequests(
        driverRequests.filter(({ id }) => id !== payload.id),
      );
      if (
        payload.status === DriverRequestStatus.Approved &&
        authUser?.user &&
        data?.vehicle
      ) {
        updateAuthUser({
          ...authUser,
          user: {
            ...authUser.user,
            drivingVehicles: [
              ...(authUser.user.drivingVehicles || []),
              data.vehicle,
            ],
          },
        });
      }
    } catch (error) {
      throw error;
    } finally {
      updateLoadingSubmitDriverRequest(false);
    }
  };

  const fetchVehicleById = async (payload: IGetVehicleByIdRequest) => {
    updateLoadingVehicle(true);
    try {
      const data = await getVehicleByIdRequest(payload);
      if (data && authUser?.user) {
        updateAuthUser({
          ...authUser,
          user: {
            ...authUser.user,
            drivingVehicles: [
              ...(authUser.user.drivingVehicles || []).filter(
                ({ id }) => id !== payload.id,
              ),
              data,
            ],
          },
        });
      }
    } catch (error) {
      throw error;
    } finally {
      updateLoadingVehicle(false);
    }
  };

  const fetchVehicleReportSummary = async (
    payload: IGetVehicleReportSummaryRequest,
  ) => {
    updateLoadingVehicleReportSummary(true);
    try {
      const data = await getVehicleReportSummaryRequest(payload);

      updateVehicleReportSummary(data);
    } catch (error) {
      throw error;
    } finally {
      updateLoadingVehicleReportSummary(false);
    }
  };

  const fetchVehiclesReportSummary = async (
    payload: IGetVehiclesReportSummaryRequest,
  ) => {
    updateLoadingVehiclesReportSummary(true);
    try {
      const data = await getVehiclesReportSummaryRequest(payload);
      updateVehiclesReportSummary(data);
    } catch (error) {
      throw error;
    } finally {
      updateLoadingVehiclesReportSummary(false);
    }
  };

  const fetchVehicleReport = async (payload: IGetVehicleReportRequest) => {
    updateLoadingVehicleReport(true);
    try {
      const { startDate, endDate, ...rest } = payload;

      const data = await getVehicleReportRequest({
        ...rest,
        startDate: setToStartOfDay(dayjs(startDate)).toDate(),
        endDate: setToEndOfDay(dayjs(endDate)).toDate(),
      });
      updateVehicleReport(data);
    } catch (error) {
      throw error;
    } finally {
      updateLoadingVehicleReport(false);
    }
  };

  const cleanVehicleReport = () => {
    updateVehicleReport(defaultAppContext.vehicleReport);
  };

  const fetchDriverReport = async (payload: IGetDriverReportRequest) => {
    updateLoadingDriverReport(true);
    try {
      const { startDate, endDate, ...rest } = payload;

      const data = await getDriverReportRequest({
        ...rest,
        startDate: setToStartOfDay(dayjs(startDate)).toDate(),
        endDate: setToEndOfDay(dayjs(endDate)).toDate(),
      });
      updateDriverReport(data);
    } catch (error) {
      throw error;
    } finally {
      updateLoadingDriverReport(false);
    }
  };

  const cleanDriverReport = () => {
    updateDriverReport(defaultAppContext.driverReport);
  };

  const fetchDailyAccounting = async (payload: IGetDailyAccountingRequest) => {
    updateLoadingDailyAccounting(true);
    try {
      const data = await getDailyAccountingRequest(payload);
      updateDailyAccounting(data);
    } catch (error) {
      throw error;
    } finally {
      updateLoadingDailyAccounting(false);
    }
  };

  const createSubscription = async (payload: ICreateSubscriptionRequest) => {
    updateLoadingCreateSubscription(true);
    try {
      const url = await createSubscriptionRequest(payload);
      updateCheckoutUrl(url);
    } catch (error) {
      throw error;
    } finally {
      updateLoadingCreateSubscription(false);
    }
  };

  const getCustomerPortal = async (payload: IGetCustomerPortalRequest) => {
    updateLoadingGetCustomerPortal(true);
    try {
      const url = await getCustomerPortalRequest(payload);
      updateCustomerPortalUrl(url);
    } catch (error) {
      throw error;
    } finally {
      updateLoadingGetCustomerPortal(false);
    }
  };

  const fetchSubscriptions = async () => {
    updateLoadingSubscriptions(true);
    try {
      const data = await getSubscriptionsRequest();
      updateSubscriptions(data);
    } catch (error) {
      throw error;
    } finally {
      updateLoadingSubscriptions(false);
    }
  };

  const hasValidSubscription = async (
    payload: IHasValidSubscriptionRequest,
  ) => {
    updateLoadingHasValidSubscription(true);
    try {
      const data = await hasValidSubscriptionRequest(payload);
      return data;
    } catch (error) {
      throw error;
    } finally {
      updateLoadingHasValidSubscription(false);
    }
  };

  useEffect(() => {
    getAuth();
  }, [getAuth]);

  useEffect(() => {
    const paths = location.pathname.split("/");
    const targetUserType = paths[1] as UserType;

    if (
      targetUserType &&
      targetUserType in UserType &&
      userType !== targetUserType
    ) {
      setUserType(targetUserType);
      UserTypeStorage.store(targetUserType);
    }
  }, [location, userType]);

  return {
    authUser,
    verificationId,
    userType,
    sideMenuCollapsed,
    getAuth,
    sendOtp,
    signIn,
    signOut,
    updateUserType,
    toggleSideMenuCollapsed,
    createOrUpdateUser,
    createVehicle,
    updateVehicle,
    deleteVehicle,
    reminders,
    reminderTypes,
    fetchReminders,
    fetchReminderTypes,
    createReminder,
    accountingTypes,
    fetchAccountingTypes,
    createAccounting,
    drivers,
    fetchDrivers,
    createOrUpdateDriver,
    deleteDriver,
    startDriving,
    stopDriving,
    driverRequests,
    fetchDriverRequests,
    submitDriverRequest,
    fetchVehicleById,
    vehicleReportSummary,
    vehiclesReportSummary,
    fetchVehicleReportSummary,
    fetchVehiclesReportSummary,
    vehicleReport,
    fetchVehicleReport,
    cleanVehicleReport,
    driverReport,
    fetchDriverReport,
    cleanDriverReport,
    dailyAccounting,
    fetchDailyAccounting,
    createSubscription,
    checkoutUrl,
    getCustomerPortal,
    customerPortalUrl,
    subscriptions,
    fetchSubscriptions,
    hasValidSubscription,
    loadingAuth,
    loadingOtp,
    loadingSignIn,
    loadingUser,
    loadingVehicle,
    loadingDeleteVehicle,
    loadingReminder,
    loadingReminderType,
    loadingAccounting,
    loadingAccountingType,
    loadingDrivers,
    loadingCreateOrUpdateDriver,
    loadingDeleteDriver,
    loadingDriving,
    loadingDriverRequests,
    loadingSubmitDriverRequest,
    loadingVehicleReportSummary,
    loadingVehiclesReportSummary,
    loadingVehicleReport,
    loadingDriverReport,
    loadingDailyAccounting,
    loadingCreateSubscription,
    loadingGetCustomerPortal,
    loadingSubscriptions,
    loadingHasValidSubscription,
  };
};

const AppContext = createContext<AppContextState>(defaultAppContext);

export const useApp = (): AppContextState => {
  return useContext(AppContext);
};

export const AppContextConsumer = AppContext.Consumer;

export const AppContextProvider: React.FC<{ children: React.ReactElement }> = ({
  children,
}) => {
  const contextValues = useAppContext(defaultAppContext);

  return (
    <AppContext.Provider value={contextValues}>{children}</AppContext.Provider>
  );
};
