import { message } from "antd";
import {
  IOtpRequest,
  ISignInRequest,
} from "../../containers/Authentication/types";
import {
  getFirebaseAuth,
  generateRecaptcha,
  sendOTP,
  signInWithOTP,
  UserCredential,
  RecaptchaVerifier,
  Auth,
  logout,
} from "tasit-defteri-firebase-functions";

export async function otpRequest(
  payload: IOtpRequest,
): Promise<string | null | undefined> {
  const recaptchaVerifier: RecaptchaVerifier = await generateRecaptcha({
    recaptchaContainerId: "recaptcha",
  });

  try {
    return await sendOTP({
      phoneNumber: payload.phoneNumber,
      recaptchaVerifier,
    });
  } catch (error: any) {
    message.error(error.message);
  }
}

export async function signInRequest(payload: ISignInRequest): Promise<any> {
  try {
    const result: UserCredential = await signInWithOTP(payload);
    return result;
  } catch (error: any) {
    // console.log(error);
    // message.error(error.message);
    const errMsg = "Geçersiz Onay Kodu";
    message.error(errMsg);
  }
}

export function getAuthRequest(): Promise<Auth> | undefined {
  try {
    return getFirebaseAuth();
  } catch (error: any) {
    message.error(error.message);
  }
}

export async function signOutRequest(): Promise<void> {
  try {
    await logout();
  } catch (error: any) {
    message.error(error.message);
  }
}
