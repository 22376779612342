import React from "react";
import { Row } from "antd";
import Logo from "../../images/logo.png";
// import "./index.css";

interface SplashScreenProps {
  loading: boolean;
  children?: React.ReactNode;
}

const SplashScreen: React.FC<SplashScreenProps> = ({ loading, children }) => {
  if (loading) {
    return (
      <Row style={{ height: "100vh" }} justify="center" align="middle">
        <img alt="logo" width="250px" src={Logo} />
      </Row>
    );
  }

  return <>{children}</>;
};

export default SplashScreen;
