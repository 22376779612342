import React from "react";
import { Breadcrumb } from "antd";
import { Link } from "react-router-dom";
import { HomeOutlined } from "@ant-design/icons";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import { routes } from "../../constants/urls";
import { useApp } from "../../context/app.context";

const Breadcrumbs: React.FC = () => {
  const { authUser, drivers } = useApp();
  const breadcrumbs = useBreadcrumbs(routes);

  const getPlateNo = (vehicleId?: string) =>
    authUser?.user?.drivingVehicles?.find(
      ({ id }) => id.toString() === vehicleId?.toString(),
    )?.plateNo;

  const getDriverName = (driverId?: string) => {
    const driver = drivers
      .filter(({ id }) => id)
      .find(({ id }) => id.toString() === driverId?.toString());
    if (driver) {
      return `${driver.name} ${driver.surname}`;
    } else {
      return "";
    }
  };

  const itemRender = (route: any, params: any, items: any, paths: any) => {
    const last = items.indexOf(route) === items.length - 1;
    return last ? (
      <span>{route.title}</span>
    ) : (
      <Link to={route.path}>{route.title}</Link>
    );
  };

  return (
    <Breadcrumb
      separator=">"
      itemRender={itemRender}
      items={[
        {
          path: "/",
          title: <HomeOutlined />,
        },
        ...breadcrumbs
          .filter(({ match: { route } }) => route?.breadcrumb)
          .map(({ match: { route, pathname, params } }) => ({
            path: pathname,
            title: route?.breadcrumb
              ? typeof route.breadcrumb === "function"
                ? String(
                    Object.keys(params)?.slice(-1)?.[0] === "vehicleId"
                      ? getPlateNo(params?.vehicleId)
                      : getDriverName(params?.driverId),
                  )
                : String(route.breadcrumb)
              : "",
          })),
      ]}
    />
  );
};

export default Breadcrumbs;
