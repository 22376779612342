import { message } from "antd";
import {
  getRemindersByUserId,
  IGetRemindersByUserIdRequest,
  getReminderTypes,
  createReminder,
  ICreateReminderRequest,
  IReminder,
  IReminderType,
} from "tasit-defteri-firebase-functions";
import { convertDateFields } from "../../utils/convertDateFields";

export async function getRemindersByUserIdRequest(
  payload: IGetRemindersByUserIdRequest,
): Promise<IReminder[]> {
  try {
    const result = await getRemindersByUserId(payload);
    convertDateFields(result);
    return result;
  } catch (error: any) {
    message.error(error.message);
    return [];
  }
}

export async function createReminderRequest(
  payload: ICreateReminderRequest,
): Promise<void> {
  try {
    await createReminder(payload);
    message.success("Hatırlatıcı başarıyla oluşturuldu.");
  } catch (error: any) {
    message.error(error.message);
  }
}

export async function getReminderTypesRequest(): Promise<IReminderType[]> {
  try {
    const result = await getReminderTypes();
    return result;
  } catch (error: any) {
    message.error(error.message);
    return [];
  }
}
