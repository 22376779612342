export enum Url {
  BaseRoute = "/",
  ProfileRoute = "/profile/",
  OwnerRoute = "/owner/",
  OwnerVehiclesRoute = "/owner/vehicles/",
  AddVehicleRoute = "/owner/vehicles/add/",
  UpdateVehicleRoute = "/owner/vehicles/:vehicleId/edit/",
  OwnerVehicleRoute = "/owner/vehicles/:vehicleId/",
  DriversRoute = "/owner/vehicles/:vehicleId/drivers/",
  AddDriverRoute = "/owner/vehicles/:vehicleId/drivers/add/",
  UpdateDriverRoute = "/owner/vehicles/:vehicleId/drivers/:driverId/edit/",
  AddOwnerAccountingRoute = "/owner/vehicles/:vehicleId/accounting/add/",
  OwnerReportRoute = "/owner/vehicles/:vehicleId/report/",
  AddReminderRoute = "/owner/vehicles/:vehicleId/reminders/add/",
  DriverRoute = "/driver/vehicles/",
  DriverVehicleRoute = "/driver/vehicles/:vehicleId/",
  AddDriverAccountingRoute = "/driver/vehicles/:vehicleId/accounting/add/",
  DriverReportRoute = "/driver/vehicles/:vehicleId/report/",
  SubscriptionsRoute = "/subscriptions/",
  PrivacyPolicyRoute = "/privacy/",
}

export const routes = [
  { path: Url.BaseRoute },
  { path: Url.ProfileRoute, breadcrumb: "Hesabım" },
  { path: Url.DriverRoute, breadcrumb: "Araçlarım" },
  { path: Url.OwnerRoute },
  { path: Url.OwnerVehiclesRoute, breadcrumb: "Araçlarım" },
  {
    path: Url.DriverVehicleRoute,
    breadcrumb: ({ match }: { match: any }) => match.params.vehicleId,
  },
  {
    path: Url.OwnerVehicleRoute,
    breadcrumb: ({ match }: { match: any }) => match.params.vehicleId,
  },
  { path: Url.DriversRoute, breadcrumb: "Şoförlerim" },
  { path: Url.AddDriverRoute, breadcrumb: "Ekle" },
  {
    path: Url.UpdateDriverRoute,
    breadcrumb: ({ match }: { match: any }) => match.params.driverId,
  },
  { path: Url.AddVehicleRoute, breadcrumb: "Ekle" },
  { path: Url.UpdateVehicleRoute, breadcrumb: "Düzenle" },
  { path: Url.AddReminderRoute, breadcrumb: "Hatırlatıcı" },
  { path: Url.AddOwnerAccountingRoute, breadcrumb: "Masraf" },
  { path: Url.AddDriverAccountingRoute, breadcrumb: "Masraf" },
  { path: Url.OwnerReportRoute, breadcrumb: "Rapor" },
  { path: Url.DriverReportRoute, breadcrumb: "Rapor" },
  { path: Url.SubscriptionsRoute, breadcrumb: "Aboneliklerim" },
  { path: Url.PrivacyPolicyRoute, breadcrumb: "Gizlilik" },
];
