import { message } from "antd";
import {
  getVehicleReportSummary,
  getVehiclesReportSummary,
  IVehiclesReportSummary,
  IGetVehicleReportSummaryRequest,
  IGetVehiclesReportSummaryRequest,
  getVehicleReport,
  IVehicleReport,
  IGetVehicleReportRequest,
  getDriverReport,
  IDriverReport,
  IGetDriverReportRequest,
} from "tasit-defteri-firebase-functions";

export async function getVehicleReportSummaryRequest(
  payload: IGetVehicleReportSummaryRequest,
): Promise<IVehiclesReportSummary[]> {
  try {
    return await getVehicleReportSummary(payload);
  } catch (error: any) {
    message.error(error.message);
    return [];
  }
}

export async function getVehiclesReportSummaryRequest(
  payload: IGetVehiclesReportSummaryRequest,
): Promise<IVehiclesReportSummary[]> {
  try {
    return await getVehiclesReportSummary(payload);
  } catch (error: any) {
    message.error(error.message);
    return [];
  }
}

export async function getVehicleReportRequest(
  payload: IGetVehicleReportRequest,
): Promise<IVehicleReport[]> {
  try {
    return await getVehicleReport(payload);
  } catch (error: any) {
    message.error(error.message);
    return [];
  }
}

export async function getDriverReportRequest(
  payload: IGetDriverReportRequest,
): Promise<IDriverReport[]> {
  try {
    return await getDriverReport(payload);
  } catch (error: any) {
    message.error(error.message);
    return [];
  }
}
