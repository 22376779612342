import { message } from "antd";
import {
  getDriversByVehicleId,
  IGetDriversByVehicleIdRequest,
  ICreateOrUpdateDriverRequest,
  createOrUpdateDriver,
  IDeleteDriverRequest,
  deleteDriver,
  submitDriverRequest,
  ISubmitDriverRequest,
  getDriverRequestsByPhone,
  IGetDriverRequestsByPhone,
  IDriverRequest,
} from "tasit-defteri-firebase-functions";

export async function getDriversByVehicleIdRequest(
  payload: IGetDriversByVehicleIdRequest,
): Promise<any[]> {
  try {
    const result = await getDriversByVehicleId(payload);
    return result;
  } catch (error: any) {
    message.error(error.message);
    return [];
  }
}

export async function createOrUpdateDriverRequest(
  payload: ICreateOrUpdateDriverRequest,
): Promise<void> {
  try {
    await createOrUpdateDriver(payload);
    message.success("Şoför kaydedildi!");
  } catch (error: any) {
    message.error(error.message);
  }
}

export async function deleteDriverRequest(
  payload: IDeleteDriverRequest,
): Promise<void> {
  try {
    await deleteDriver(payload);
    message.success("Şoför Silindi!");
  } catch (error: any) {
    message.error(error.message);
  }
}

export async function getDriverRequestsByPhoneRequest(
  payload: IGetDriverRequestsByPhone,
): Promise<IDriverRequest[]> {
  try {
    const result = await getDriverRequestsByPhone(payload);
    return result;
  } catch (error: any) {
    message.error(error.message);
    return [];
  }
}

export async function submitDriverRequestRequest(
  payload: ISubmitDriverRequest,
): Promise<IDriverRequest | undefined> {
  try {
    const result = await submitDriverRequest(payload);
    message.success("Şoför Talebi Yanıtlandı!");
    return result;
  } catch (error: any) {
    message.error(error.message);
  }
}
